import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from 'utils/helpers';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

const propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  hint: PropTypes.node,
  creatable: PropTypes.bool,
  clearable: PropTypes.bool,
  label: PropTypes.node,
  labelTip: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  parentCallback: PropTypes.func,
  placeholder: PropTypes.node,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
  selectInFieldArray: PropTypes.bool,
  showWithoutOptions: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: null,
  selectClassName: null,
  creatable: false,
  clearable: false,
  hint: null,
  label: null,
  labelTip: null,
  isMulti: false,
  options: [],
  parentCallback: null,
  placeholder: null,
  defaultValue: null,
  selectInFieldArray: false,
  showWithoutOptions: false,
  disabled: false,
};

class RenderSelectField extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    const {
      input: { value }, defaultValue, isMulti, options,
    } = this.props;

    let val = value || defaultValue;
    if (isMulti) {
      val = val !== null ? val.map(String) : val;
      const option = val !== null ? options.filter(e => val.includes(String(e.value))) : null;
      this.setState({ value: option });
    } else {
      const option = options.filter(e => e.value === val);
      this.setState({ value: option.length ? option[0] : null });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let val = nextProps.input.value || nextProps.defaultValue;
    if (nextProps.isMulti) {
      let option = [];
      if (val && val.some(v => typeof v === 'object')) {
        option = val !== null ? nextProps.options.filter((el) => val.some((f) => f.value === el.value)) : null;
      } else {
        val = val !== null ? val.map(String) : val;
        option = val !== null ? nextProps.options.filter(e => val.includes(String(e.value))) : null;
      }
      this.setState({ value: option });
    } else {
      let option = [];
      if (typeof val === 'object') {
        option = val ? nextProps.options.filter(e => e.value === val.value) : [];
      } else {
        option = nextProps.options.filter(e => e.value === val);
      }
      this.setState({ value: option.length ? option[0] : null });
    }
  }

  handleChange(selected) {
    const {
      input: { onChange }, parentCallback, isMulti,
    } = this.props;

    if (isMulti) {
      const value = selected.length ? selected.map(el => el.value) : null;
      onChange(value !== null ? value.map(String) : value);
    } else {
      onChange(selected ? selected.value : null);
    }

    // Use only if you have to change the parent component
    if (parentCallback) {
      parentCallback(selected ? selected.value : '');
    }
  }

  render() {
    const {
      input: { onBlur, name }, meta, selectInFieldArray, labelTip, showWithoutOptions, disabled,
      className, selectClassName, creatable, clearable, hint, label, placeholder, ...rest
    } = this.props;

    const { value } = this.state;

    if (!rest.options.length && !showWithoutOptions) return null;

    return (
      <div className={`${className} ${meta.touched && meta.error ? 'error' : ''}`}>
        <Label label={label} tip={labelTip} />
        <div className="normal-select">

          {creatable
            ?
              <CreatableSelect
                onChange={this.handleChange}
                name={name}
                isDisabled={disabled}
                placeholder={placeholder}
                isClearable={clearable}
                className={selectClassName}
                {...rest}
                value={value}
                styles={customStyles}
                touchUi={false}
              />
            :
              <Select
                onChange={this.handleChange}
                name={name}
                options={rest.options}
                placeholder={placeholder}
                className={selectClassName}
                isClearable={clearable}
                isDisabled={disabled}
                {...rest}
                value={value}
                styles={customStyles}
                touchUi={false}
              />
          }
          <ErrorMessage {...meta} />
        </div>
        {hint && <span className="hint">{hint}</span>}
      </div>
    );
  }
}

RenderSelectField.propTypes = propTypes;
RenderSelectField.defaultProps = defaultProps;

export default RenderSelectField;
